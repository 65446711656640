export default [
	"md",
	"markdown",
	"mdown",
	"mkdn",
	"mkd",
	"mdwn",
	"mkdown",
	"ron"
]
;